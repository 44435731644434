import { TextareaHTMLAttributes } from 'react';
import { Field } from 'react-final-form';
import MetaErrorHandler from './meta-error-handler';

export interface FormFieldTextareaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  label: string,
  name: string,
}

const FormFieldTextarea = ({
  label,
  name,
  rows = 3,
  ...textareaProps
}: FormFieldTextareaProps) => (
  <Field name={name}>
    {({ input, meta }) => (
      <label className="block" htmlFor={label}>
        <span
          className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark"
        >
          {label}
        </span>
        <textarea
          rows={rows}
          className="mt-2 block w-full text-xs sm:text-sm rounded-md bg-light shadow-sm
          border-background-stroke
          dark:border-background-stroke-dark
          dark:text-text-color-low-em
          dark:bg-background-main-dark focus:border-gray-500
          focus:bg-white focus:ring-0"
          {...input}
          {...textareaProps}
        />
        <MetaErrorHandler meta={meta} />
      </label>
    )}
  </Field>
);

export default FormFieldTextarea;
