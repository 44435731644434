import Button from '@app/components/button';
import Modal from '@app/components/modal';
import { frontendApi } from '@app/lib/api';
import { useState, Fragment } from 'react';
import { Form } from 'react-final-form';
import { toast } from 'react-toastify';
import { PulseLoader } from '@app/components/table-pagination';
import useSWR, { mutate } from 'swr';
import ReactTooltip from 'react-tooltip';
import { omit } from 'lodash';
import { RfpItemDetails } from '@app/components/submitted-bid-detailts';
import { useThemeContext } from '@app/contexts/theme-context';
import Icon from '@mdi/react';
import {
  mdiCheckCircle, mdiInformation, mdiMenuDown, mdiMenuUp, mdiScript,
} from '@mdi/js';
import FormFieldCurrency from './fields/currency';
import FormFieldNumber from './fields/number';
import FormFieldPercentage from './fields/percentage';
import FormFieldTextarea from './fields/textarea';
import FormFieldInput from './fields/input';

export interface SubmitBidFormProps {
  rfpItemId: number,
  buttonTitle?: string,
  hasSubmitted?: boolean,
  handleOnSubmitSuccess: (rfpItemId: number) => void,
  buttonClassName?: string,
}

export type rfpItemInputType = 'Currency' | 'Number' | 'Percentage' | 'Currency or Percentage';

export interface switchFormFieldProps {
  // eslint-disable-next-line camelcase
  input_type: {
    // eslint-disable-next-line camelcase
    input_type: rfpItemInputType,
  },
  name: string,
  label: string,
  description: string,
}

const SubmitBidForm = ({
  rfpItemId, hasSubmitted = false, buttonTitle = 'Submit a Bid',
  handleOnSubmitSuccess, buttonClassName,
}: SubmitBidFormProps) => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { data: rfpItem } = useSWR(`/bidding/open/${rfpItemId}`);
  const { isMobile } = useThemeContext();

  const handleOnSubmit = async (payload, { reset }) => {
    try {
      const charges = omit(payload, 'note');

      const { data } = await frontendApi.post(`/bidding/bid/${rfpItemId}/me`, { charges, note: payload.note });

      toast.success(data.message);
      reset();

      setIsModalOpen(false);

      // updates panel data count
      mutate('/bidding/track-submitted-bids');
      handleOnSubmitSuccess(rfpItemId);
    } catch (error) {
      console.error(error);
    }
  };

  const renderDetails = rfpItem && isDetailsOpen && <RfpItemDetails rfpItem={rfpItem} />;

  // currency, number, percentage, Currency/Percentage
  const switchFormField = (name, label, { input_type: inputType }: switchFormFieldProps['input_type']) => {
    switch (inputType) {
      case 'Currency':
        return (
          <FormFieldCurrency
            name={name}
            label={label}
            prefix="$"
            required
          />
        );
      case 'Percentage':
        return (
          <FormFieldPercentage
            name={name}
            label={label}
            required
          />
        );
      case 'Number':
        return <FormFieldNumber name={name} label={label} required />;
      case 'Currency or Percentage':
      default:
        return <FormFieldInput name={name} type="text" label={label} required />;
    }
  };

  const renderFormField = (props: switchFormFieldProps) => {
    const { name, label, description } = props;

    // Temporarily here. Can't trigger onMount of modal since the data-tip hasn't been generated yet
    if (description) {
      // ReactTooltip guide for modal: https://www.npmjs.com/package/react-tooltip
      ReactTooltip.rebuild();
    }

    const renderLabel = description
      ? (
        <div className="flex items-center justify-between space-x-1">
          <span className="text-xs md:text-body-md">
            {label}
          </span>
          <Icon
            path={mdiInformation}
            size={0.8}
            className="text-text-color-mid-em
          dark:text-text-color-mid-em-dark"
            data-tip={description}
            data-for="info"
          />
        </div>
      )
      : label;

    return switchFormField(name, renderLabel, props.input_type);
  };

  const renderForm = (
    <Form
      onSubmit={handleOnSubmit}
      render={({ handleSubmit, submitting }) => (
        <form onSubmit={handleSubmit}>
          <div className="md:p-3">
            <div className="flex flex-row space-x-2">
              <div className="text-primary">
                <Icon path={mdiScript} size={1} />
              </div>
              <button
                type="button"
                className="font-bold mb-5 cursor-pointer"
                onClick={() => setIsDetailsOpen(!isDetailsOpen)}
              >
                <div className="flex flex-row">
                  <div className="label-lg text-text-color-high-em dark:text-text-color-high-em-dark">
                    Submit Bid for
                    {' '}
                    {rfpItem.rfp.client.name}
                  </div>
                  <div>
                    {isDetailsOpen ? <Icon path={mdiMenuUp} size={1} /> : <Icon path={mdiMenuDown} size={1} />}
                  </div>
                </div>
              </button>
            </div>
            <div className="ml-2">
              {renderDetails}
            </div>
            <div className="mb-5 space-y-3">
              <div className="grid text-body-md grid-cols-1 xs:grid-cols-2 gap-4">
                {rfpItem.rfp_item_form.charge_type.map((cT) => <div key={cT.id}>{renderFormField(cT)}</div>)}
              </div>
              <FormFieldTextarea name="note" label="Note" />
            </div>
            <div className="flex items-center justify-end space-x-5">
              <Button buttonType="secondary" className="!text-xs xs:!text-sm" onClick={() => setIsModalOpen(false)}>
                Cancel
              </Button>
              <Button type="submit" className="!text-xs xs:!text-sm" disabled={submitting}>
                {submitting ? 'Submitting..' : 'Submit'}
              </Button>
            </div>
          </div>
        </form>
      )}
    />
  );

  return (
    <Fragment>
      <Modal
        overrideStyles={
          {
            content: {
              width: isMobile ? 'auto' : '50vw',
              maxWidth: isMobile ? '90vw' : null,
              maxHeight: '80vh',
            },
          }
        }
        isOpen={isModalOpen}
        onRequestClose={() => setIsModalOpen(false)}
      >
        {rfpItem ? renderForm : <PulseLoader />}
      </Modal>
      <Button
        className={buttonClassName}
        buttonType={hasSubmitted ? 'success-light-disabled' : 'primary'}
        onClick={() => setIsModalOpen(true)}
        startIcon={hasSubmitted ? <Icon path={mdiCheckCircle} size={0.8} /> : null}
      >
        {hasSubmitted ? (
          <span>
            Submitted
          </span>
        ) : buttonTitle}
      </Button>
    </Fragment>
  );
};

export default SubmitBidForm;
