import { useThemeContext } from '@app/contexts/theme-context';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';
import Icon from '@mdi/react'; import ReadMoreReact from 'read-more-react';
import { Fragment, useState } from 'react';

type Prop = {
  submittedBy: string;
  submittedByEmail: string;
  note: string;
};
const SubmittedBidRemarks = ({ submittedBy, submittedByEmail, note }: Prop) => {
  const { isMobile } = useThemeContext();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const onClick = () => {
    setIsCollapsed((c) => !c);
  };

  const divProps = isMobile
    ? {
      role: 'button',
      tabIndex: 0,
      onClick,
      onKeyPress: onClick,
    }
    : {};
  return (
    <Fragment>
      <div className="p-2">
        <span
          className="flex items-center text-text-color-high-em dark:text-text-color-light label-md"
          {...divProps}
        >
          Submitted By
          <span className="inline md:hidden">
            {isCollapsed ? <Icon size={1} path={mdiMenuUp} /> : <Icon size={1} path={mdiMenuDown} />}
          </span>
        </span>
        <span
          className={`text-sm text-text-color-mid-em dark:text-text-color-low-em ${
            isCollapsed ? '' : 'hidden'
          }`}
        >
          {`${submittedBy} (${submittedByEmail})`}
        </span>
      </div>
      <div className="p-2">
        <span className="block text-text-color-high-em dark:text-text-color-light label-md">
          Note
        </span>
        <span className="text-sm text-text-color-mid-em dark:text-text-color-low-em">
          <ReadMoreReact
            text={note}
            readMoreText={(
              <span className="label-md text-primary">
                READ MORE
              </span>
            )}
          />
        </span>
      </div>
    </Fragment>
  );
};

export default SubmittedBidRemarks;
