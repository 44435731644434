import { MutableRefObject } from 'react';
import {
  Cell,
  Column, HeaderGroup, Row, useTable,
} from 'react-table';

export const tableContainerDefaultClassName = 'pb-5';
export const tableDefaultClassName = 'min-w-full dark:text-gray-400 mb-5 rounded-md shadow-md';
export const tableDefaultHeaderRowClassName = `bg-background-low-em dark:bg-background-low-em-dark
 text-text-color-low-em dark:text-white text-xs border dark:border-text-color-mid-em`;
export const tableDefaulttHeaderClassName = `px-6 py-3 text-left text-text-color-mid-em dark:text-light
 uppercase tracking-wider cursor-pointer`;
export const tableDefaultBodyClassName = `bg-light dark:bg-background-main-dark text-sm divide-y divide-gray-200
border dark:border-text-color-mid-em`;
export const tableDefaultBodyRowClassName = `hover:bg-gray-200 text-text-color-mid-em 
dark:text-text-color-low-em dark:hover:bg-gray-700`;
export const tableDefaultBodyTdClassName = 'px-6 py-4 border-b dark:border-b-text-color-mid-em';
export interface TableProps {
  columns: Column<{}>[],
  data: {}[],
  getRowProps?: (r: any) => ({}),
  tableRef?: MutableRefObject<any>
}

// Defaults the getter to this if not declared
export const defaultTablePropGetter = () => ({});

const Table = ({
  data,
  columns,
  tableRef,
  // An awesome way to insert properties to a row
  getRowProps = defaultTablePropGetter,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data });

  return (
    <table ref={tableRef} className={tableDefaultClassName} {...getTableProps()}>
      <thead className={tableDefaultHeaderRowClassName}>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: HeaderGroup & { className: string }) => {
              // eslint-disable-next-line no-nested-ternary
              const isSorted = column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : '';

              return (
                <th
                  {...column.getHeaderProps({
                    className: column.className || tableDefaulttHeaderClassName,
                  })}
                >
                  {column.render('Header')}
                  <span>
                    {isSorted}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody
        className={tableDefaultBodyClassName}
        {...getTableBodyProps()}
      >
        {rows.map((row: Row & { className: string }, i) => {
          prepareRow(row);
          return (
            <tr
              key={i}
              {...row.getRowProps({
                className: row.className || tableDefaultBodyRowClassName,
              })}
              {...getRowProps(row)}
            >
              {row.cells.map((cell: Cell & { className: string }) => (
                <td
                  {...cell.getCellProps({
                    className: cell.className || tableDefaultBodyTdClassName,
                  })}
                >
                  {cell.render('Cell')}
                </td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Table;
