import { useState, useEffect, Fragment } from 'react';
import { isEmpty, uniq } from 'lodash';
import { PulseLoader } from 'react-spinners';
import Icon from '@mdi/react';
import {
  mdiDumpTruck, mdiMapMarker, mdiRecycleVariant, mdiStar, mdiTimetable,
} from '@mdi/js';
import { differenceInMinutes } from 'date-fns';
import SubmitBidForm from '@app/forms/submit-bid';
import Button from '@app/components/button';
import { formatDate } from '@app/lib/date-fns';
import { extractedTailwindTheme, isWithinAWeek } from '@app/lib/helpers';
import useSWR, { mutate } from 'swr';

export interface ProfileHeaderDetailsProps {
  value: string,
  icon: string,
  iconSize?: number,
  overrideTextClass?: string
}

const LS_FEATURED_BID_KEY = 'bidding:featured-bid';
const FEATURED_BID_MINUTE_HIDE_DRURATION = 15;

export const FeaturedBidDetails = ({
  icon,
  value,
  iconSize = 0.7,
  overrideTextClass = 'text-body-sm md:text-body-xs text-text-color-mid-em  dark:text-text-color-low-em',
}: ProfileHeaderDetailsProps) => (
  <div className="flex space-x-2 items-center">
    <div className="w-3 md:w-4 h-4 items-center justify-center flex">
      <span>
        <Icon size={iconSize} path={icon} />
      </span>
    </div>
    <p className={overrideTextClass}>{value}</p>
  </div>
);

export interface FeaturedBidProp {
  closeFeaturedBid?: () => void;
}

const FeaturedBid = ({ closeFeaturedBid = null }: FeaturedBidProp) => {
  const [excludedBidIds, setExcludedBidIds] = useState<number[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(true);
  const queryString = excludedBidIds.map((id) => `excludedBidIds=${id}`).join('&');

  const { data } = useSWR(`/bidding/featured-bid/me?${queryString}`, {
    revalidateOnFocus: false,
    refreshWhenHidden: false,
    refreshInterval: 0,
    shouldRetryOnError: false,
    onSuccess: () => setIsFetching(false),
    onError: () => setIsFetching(false),
  });

  const [open, setOpen] = useState(false);

  const handleShuffle = async () => {
    setIsFetching(true);
    setExcludedBidIds((prev) => uniq([...prev, data.id]));
    await mutate('/bidding/featured-bid/me');
  };

  useEffect(() => {
    const hasAnnounce = localStorage.getItem(LS_FEATURED_BID_KEY);
    const date = new Date(hasAnnounce);
    const minuteCount = differenceInMinutes(new Date(), date);

    if (minuteCount > FEATURED_BID_MINUTE_HIDE_DRURATION) {
      return setOpen(true);
    }

    return setOpen(false);
  }, []);

  const shouldEmphasizeDueDate = data?.rfp?.end_date != null && isWithinAWeek(new Date(data.rfp.end_date));
  const renderDetails = !isEmpty(data) && (
    <Fragment>
      <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-3">
        <h4 className="text-lg font-bold">
          {data.rfp.client.name}
        </h4>
        <ul className="flex flex-col sm:flex-row sm:space-x-2 text-xs">
          <li>
            Start Date
            {' '}
            <b>
              {formatDate(data.rfp.start_date)}
            </b>
          </li>
          <li>
            Due Date:
            {' '}
            <b className={shouldEmphasizeDueDate ? 'text-red-500' : ''}>
              {formatDate(data.rfp.end_date)}
            </b>
          </li>
        </ul>
      </div>
      <ul
        className="flex flex-col sm:flex-row space-y-1 sm:space-y-0 sm:space-x-5 text-gray-400 text-xs sm:text-sm mb-5"
      >
        <li>
          <FeaturedBidDetails
            icon={mdiMapMarker}
            value={`${data.location.address_1}, ${data.location.city} ${data.location.state} ${data.location.zip}`}
          />
        </li>
        {data.waste_stream
          && <FeaturedBidDetails icon={mdiRecycleVariant} value={data.waste_stream.name} />}
        <FeaturedBidDetails
          icon={mdiDumpTruck}
          value={`
        ${data.quantity} x
        ${data.container.container_type.name}
        ${data.container?.container_sizes?.map((cs) => cs.name).join(', ')}
        `}
        />
        <FeaturedBidDetails icon={mdiTimetable} value={data.pickup_frequency.name} />
      </ul>
      <p className="sm:max-w-7xl text-xs sm:text-sm mb-3">
        {data.rfp.client.description || 'Nothing to display'}
      </p>
    </Fragment>
  );

  const hideFeaturedBid = () => {
    localStorage.setItem(
      LS_FEATURED_BID_KEY,
      new Date().toISOString(),
    );

    setOpen(false);
  };

  return (
    open && !isEmpty(data) && (
      <div className="bg-light dark:bg-background-main-dark mb-5 p-5 rounded-md shadow-md">
        <div className="flex justify-between items-center mb-5 border-b pb-3 dark:border-b-gray-600">
          <h4 className="font-medium flex items-center">
            <Icon path={mdiStar} size={1} className="text-secondary" />
            <span className="ml-2">Featured bid</span>
          </h4>
          <div className="flex space-x-3 items-center">
            <SubmitBidForm
              rfpItemId={data.id}
              buttonTitle="Submit a Bid"
              handleOnSubmitSuccess={() => setOpen(false)}
            />
            <Button buttonType="secondary" onClick={handleShuffle}>
              Shuffle
            </Button>
            <Button
              buttonType="secondary"
              data-tip="Hide Featured Bid"
              onClick={closeFeaturedBid === null ? hideFeaturedBid : closeFeaturedBid}
            >
              Hide
            </Button>
          </div>
        </div>
        {isFetching ? (
          <div className="flex flex-row justify-center py-3">
            <PulseLoader color={extractedTailwindTheme.colors.primary.DEFAULT} size={10} />
          </div>
        ) : (
          renderDetails
        )}
      </div>
    )
  );
};

export default FeaturedBid;
