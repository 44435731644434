import { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  Cell, HeaderGroup, Row, TableInstance, useAsyncDebounce, usePagination, useSortBy, useTable,
} from 'react-table';
import { PulseLoader as ReactPulseLoader } from 'react-spinners';
import { DEFAULT_PAGE_INDEX, DEFAULT_PAGE_SIZE, DEFAULT_PAGE_SIZES } from '@app/constants/default';
import Icon from '@mdi/react';
import {
  mdiChevronDoubleLeft, mdiChevronDoubleRight, mdiChevronLeft, mdiChevronRight, mdiMenuDown, mdiMenuUp,
} from '@mdi/js';
import {
  defaultTablePropGetter, tableContainerDefaultClassName, tableDefaultBodyClassName,
  tableDefaultBodyRowClassName,
  tableDefaultBodyTdClassName,
  tableDefaultClassName, tableDefaultHeaderRowClassName, tableDefaulttHeaderClassName, TableProps,
} from './table';

export interface TabelLoaderProps extends Partial<TableInstance> {
  loading: boolean,
  total: number,
}

export interface TabelPaginationDetailsProps extends Partial<TableInstance> {
  pageSizes?: readonly number[],
  pageTotal: number,
}

export type tableMetaType = {
  pageTotal: number,
  total: number,
}

export interface TablePaginationProps extends TableProps {
  loading: boolean,
  tableMeta: tableMetaType,
  fetchData: (t: any) => void,
  filters?: {},
  pageSizes?: readonly number[],
  hiddenColumns?: string[],
}

export const INITIAL_TABLE_META: tableMetaType = {
  pageTotal: 0,
  total: 0,
};

export const PulseLoader = () => <ReactPulseLoader color="#16a34a" size={10} />;

export const TableLoader = ({
  loading, page, total, setPageSize,
  pageSize,
  pageSizes = DEFAULT_PAGE_SIZES,
}: TabelLoaderProps) => (
  <div className="text-xs text-gray-400 relative">
    {loading
      ? <PulseLoader />
      : (
        <div className="flex space-x-3 items-center">
          <select
            className="text-sm py-1 dark:bg-background-disabled-dark bg-primary-low-em border-none
            text-primary pl-1 rounded-md font-bold"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizes.map((pSize) => (
              <option key={pSize} value={pSize}>
                {pSize}
              </option>
            ))}
          </select>
          <div>
            Showing
            {' '}
            {`${page.length}-${pageSize}`}
            {' '}
            of
            {' '}
            {total}
          </div>
        </div>
      )}
  </div>
);

const tablePaginationDetailsButtonClassName = `disabled:cursor-not-allowed text-primary
disabled:text-text-color-low-em bg-primary-low-em text-primary px-3 py-1 rounded-md`;

export const TablePaginationDetails = forwardRef(({
  gotoPage,
  canPreviousPage,
  previousPage,
  nextPage,
  canNextPage,
  pageCount,
  pageIndex,
}: TabelPaginationDetailsProps, ref) => {
  useImperativeHandle(ref, () => ({

    resetPageIndexFromPaginationDetails() {
      gotoPage(0);
    },

  }));
  return (
    <div className="flex items-center space-x-2 justify-end text-sm">
      <button
        aria-label="First Page"
        type="button"
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className={tablePaginationDetailsButtonClassName}
      >
        <Icon path={mdiChevronDoubleLeft} size={0.8} />
      </button>
      <button
        aria-label="Previous Page"
        type="button"
        onClick={() => previousPage()}
        disabled={!canPreviousPage}
        className={tablePaginationDetailsButtonClassName}
      >
        <Icon path={mdiChevronLeft} size={0.8} />
      </button>
      <button
        type="button"
        disabled
        className="bg-primary text-white cursor-not-allowed px-3 py-1 rounded-md"
      >
        {pageIndex + 1}
      </button>
      <button
        aria-label="Next Page"
        type="button"
        onClick={() => nextPage()}
        disabled={!canNextPage}
        className={tablePaginationDetailsButtonClassName}
      >
        <Icon path={mdiChevronRight} size={0.8} />
      </button>
      <button
        aria-label="Last Page"
        type="button"
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className={tablePaginationDetailsButtonClassName}
      >
        <Icon path={mdiChevronDoubleRight} size={0.8} />
      </button>
    </div>
  );
});

/**
 * Find a way to handle filters inside
 */
const TablePagination = ({
  loading, tableMeta, data, fetchData, columns, hiddenColumns = [],
  pageSizes, tableRef, getRowProps = defaultTablePropGetter,
}: TablePaginationProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // Get the state from the instance
    state: { pageIndex, pageSize, sortBy },
    ...rest
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: DEFAULT_PAGE_INDEX, pageSize: DEFAULT_PAGE_SIZE, hiddenColumns }, // Pass our hoisted table state
      manualPagination: true, // Tell the usePagination
      // hook that we'll handle our own data fetching
      // This means we'll also have to provide our own
      // pageCount.
      pageCount: tableMeta.pageTotal,
      // To enable sortBy via serverside
      // https://codesandbox.io/s/react-table-pagination-and-sort-forked-6fgbb?file=/src/App.js:654-731
      manualSortBy: true,
      autoResetPage: false,
      autoResetSortBy: false,
    },
    useSortBy,
    usePagination,
  );

  // https://react-table.tanstack.com/docs/faq#how-do-i-stop-my-table-state-from-automatically-resetting-when-my-data-changes
  const onFetchDataDebounced = useAsyncDebounce(fetchData, 500);

  useEffect(() => {
    onFetchDataDebounced({ pageIndex, pageSize, sortBy });
  }, [onFetchDataDebounced, pageIndex, pageSize, sortBy]);

  return (
    <div className={tableContainerDefaultClassName}>
      <table ref={tableRef} className={tableDefaultClassName} {...getTableProps()}>
        <thead className={tableDefaultHeaderRowClassName}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup & { className: string }, i) => {
                const isSorted = column.isSortedDesc
                  ? <Icon path={mdiMenuDown} size={0.8} />
                  : <Icon path={mdiMenuUp} size={0.8} />;

                return (
                  <th
                    key={i}
                    {...column.getHeaderProps({
                      className: column.className || tableDefaulttHeaderClassName,
                    })}
                    {...column.getSortByToggleProps()}
                  >
                    <span className="flex items-center">
                      {column.render('Header')}
                      {column.isSorted && (
                      <span className="ml-1">
                        {isSorted}
                      </span>
                      )}
                    </span>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          className={tableDefaultBodyClassName}
          {...getTableBodyProps()}
        >
          {page.map((row: Row & { className: string }, i) => {
            prepareRow(row);
            return (
              <tr
                key={i}
                {...row.getRowProps({
                  className: row.className || tableDefaultBodyRowClassName,
                })}
                {...getRowProps(row)}
              >
                {row.cells.map((cell: Cell & { className: string }) => (
                  <td
                    {...cell.getCellProps({
                      className: cell.className || tableDefaultBodyTdClassName,
                    })}
                  >
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-between items-center">
        <TablePaginationDetails
          pageSize={pageSize}
          pageSizes={pageSizes}
          pageIndex={pageIndex}
          pageTotal={tableMeta.pageTotal}
          {...rest}
        />
        <TableLoader
          loading={loading}
          page={page}
          total={tableMeta.total}
          pageSize={pageSize}
          {...rest}
        />
      </div>
    </div>
  );
};

export default TablePagination;
