import { format as dateFnsformat, isValid, parse } from 'date-fns';

export const formatDate = (dateString: string, format = 'MMM dd, yyyy'): string => {
  const date = new Date(dateString);

  if (!isValid(date)) {
    return 'Invalid date';
  }

  return dateFnsformat(date, format);
};

export const validateStringIfDate = (dateString: string) => {
  const parsedDate = parse(dateString, 'yyyy-MM-dd', new Date());

  return isValid(parsedDate);
};

export default formatDate;
