import { useMemo } from 'react';

type BidStatusProp = {
    status: 'Processing' | 'Awarded' | 'Declined' | 'Submitted'
}

const BidStatus = ({ status } : BidStatusProp) => {
  const spanClass = useMemo(() => {
    switch (status) {
      case 'Declined': return 'bg-error-light text-error-dark dark:bg-error-dark dark:text-error-light';
      case 'Awarded': return 'bg-warning-light text-warning-dark dark:bg-warning-dark dark:text-warning-light';
      case 'Submitted': return 'bg-primary-light text-white dark:bg-primary-dark dark:text-white';
      default: return 'bg-success-dark text-success-light dark:bg-success-light dark:text-success-dark';
    }
  }, [status]);

  return (
    <span className={`${spanClass} label-md md:label-sm px-3 py-1 rounded-xl`}>
      {status}
    </span>
  );
};

export default BidStatus;
