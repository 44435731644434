import { ReactNode } from 'react';
import { Field } from 'react-final-form';
import NumberFormat, { NumberFormatProps } from 'react-number-format';
import MetaErrorHandler from './meta-error-handler';

export interface FormFieldCurrencyProps extends NumberFormatProps {
  label: string | ReactNode,
  name: string,
  blockClassName?: string
  initialValue?: string,
}

const FormFieldCurrency = ({
  label,
  name,
  initialValue,
  blockClassName = 'block',
  ...numberFormatProps
}: FormFieldCurrencyProps) => (
  <Field name={name} initialValue={initialValue}>
    {({ input, meta }) => (
      <label className={blockClassName} htmlFor={name}>
        <span
          className="label-md text-text-color-high-em
        dark:text-text-color-high-em-dark"
        >
          {label}
        </span>
        <NumberFormat
          className="mt-2 block w-full text-xs sm:text-sm rounded-md bg-light shadow-sm
          border-background-stroke
          dark:border-background-stroke-dark
          dark:text-text-color-low-em
          dark:bg-background-main-dark focus:border-gray-500
          focus:bg-white focus:ring-0"
          value={input.value}
          thousandSeparator
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale
          onChange={input.onChange}
          placeholder="$"
          suffix=" USD"
          {...numberFormatProps}
        />
        <MetaErrorHandler meta={meta} />
      </label>
    )}
  </Field>
);

export default FormFieldCurrency;
