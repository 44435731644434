import { useState } from 'react';
import { useThemeContext } from '@app/contexts/theme-context';
import { formatRfpItemBidChargeKey } from '@app/lib/helpers';
import { mdiMenuDown, mdiMenuUp } from '@mdi/js';
import Icon from '@mdi/react';

type ChargeItem = {
  [key: string]: string;
};
const SubmittedBidChargeBreakdown = ({
  charges,
}: {
  charges: ChargeItem[];
}) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { isMobile } = useThemeContext();

  const onClick = () => {
    setIsCollapsed((c) => !c);
  };

  const divProps = isMobile ? {
    role: 'button',
    tabIndex: 0,
    onClick,
    onKeyPress: onClick,
  } : {};

  return (
    <div
      className={`md:bg-background-disabled md:dark:bg-background-disabled-dark
       rounded-lg p-2 bg-background-low-em dark:bg-background-mid-em-dark
      md:p-3 w-full md:w-1/4 xl:1/5`}
    >
      <div
        className="flex items-center mb-2 md:mb-4 label-md text-text-color-high-em dark:text-text-color-high-em-dark"
        {...divProps}
      >
        Charges
        <span className="inline md:hidden">
          {isCollapsed ? <Icon size={1} path={mdiMenuUp} /> : <Icon size={1} path={mdiMenuDown} />}
        </span>
      </div>
      <ul className={`${isCollapsed ? '' : 'hidden'} mx-2`}>
        {Object.entries(charges).map(([key, value]) => (
          <li key={key} className="flex justify-between items-center space-y-2">
            <span
              className={`text-sm md:text-xs text-text-color-mid-em dark:text-text-color-low-em 
              md:text-text-color-high-em md:dark:text-text-color-light`}
            >
              {formatRfpItemBidChargeKey(key)}
              :
            </span>
            <span className="label-md md:label-sm dark:text-text-color-light">
              {value}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default SubmittedBidChargeBreakdown;
