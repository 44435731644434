import { biddingIdFormatter, formatRfpItemBidChargeKey, isWithinAWeek } from '@app/lib/helpers';
import { Fragment, ReactNode, useState } from 'react';
import useSWR from 'swr';
import { useThemeContext } from '@app/contexts/theme-context';
import { formatDate } from '@app/lib/date-fns';
import {
  mdiDumpTruck, mdiMapMarker, mdiRecycleVariant, mdiTimetable, mdiLeaf, mdiMenuUp, mdiMenuDown,
} from '@mdi/js';
import Icon from '@mdi/react';
import { FeaturedBidDetails } from './featured-bid';
import { PulseLoader } from './table-pagination';
import SubmittedBidChargeBreakdown from './submmitted-bid-item/submitted-bid-charge-breakdown';
import SubmittedBidRemarks from './submmitted-bid-item/submitted-bid-remarks';
import BidStatus from './bid-status';

export interface SubmittedBidDetailsProps {
  rfpItemId: number,
}

export interface RfpItemDetailsProps {
  rfpItem: any,
  isModal?: boolean,
  rfpItemBid?: any,
}

// TODO: rfpItem props

export const RfpItemDetails = ({ rfpItem, isModal, rfpItemBid }: RfpItemDetailsProps) => {
  const { isMobile } = useThemeContext();

  return (
    <div className="flex mb-5 md:space-x-5 space-x-3">
      <div>
        {rfpItem.rfp.client.img
          ? <img src={rfpItem.rfp.client.img} alt={rfpItem.rfp.client.name} width={isMobile ? '45' : '100'} />
          : (
            <div
              className="h-20 w-20 rounded-3xl flex items-center
        justify-center bg-secondary px-3 py-1
        text-3xl text-white"
            >
              <Icon
                size={2}
                path={mdiLeaf}
              />
            </div>
          )}
      </div>
      <div className="flex-1">
        <p className="dark:text-text-color-mid-em-dark
        text-text-color-mid-em text-body-sm"
        >
          {biddingIdFormatter(rfpItem.id)}
        </p>
        <div className="mb-2 space-y-2 md:space-y-0">
          <h4>
            {rfpItem.rfp.client.name}
          </h4>
          <ul className="flex md:space-x-3 flex-col md:flex-row label-sm">
            <li>
              Start Date:
              {' '}
              <b>
                {formatDate(rfpItem.rfp.start_date)}
              </b>
            </li>
            <li>
              Due Date:
              {' '}
              <b
                className={rfpItem.rfp.end_date != null && isWithinAWeek(new Date(rfpItem.rfp.end_date))
                  ? 'text-red-500' : ''}
              >
                {formatDate(rfpItem.rfp.end_date)}
              </b>
            </li>
          </ul>
        </div>
        {isModal
          ? (
            <ul className={`flex flex-col ${isMobile && 'space-y-1'} space-y-2 text-gray-400 text-body-sm mb-5`}>
              <FeaturedBidDetails
                icon={mdiMapMarker}
                value={`${rfpItem.location.address_1}, 
          ${rfpItem.location.city} ${rfpItem.location.state} ${rfpItem.location.zip}`}
              />
              <div className={`flex space-x-4 ${isMobile && 'flex-col space-x-0 space-y-1'}`}>
                {rfpItem.waste_stream
                  && <FeaturedBidDetails icon={mdiRecycleVariant} value={rfpItem.waste_stream.name} />}
                <FeaturedBidDetails
                  icon={mdiDumpTruck}
                  value={`
        ${rfpItem.quantity} x
        ${rfpItem.container.container_type.name}
        ${rfpItem.container.container_sizes.map((cs) => cs.name).join(', ')}
        `}
                />
                <FeaturedBidDetails icon={mdiTimetable} value={rfpItem.pickup_frequency.name} />
              </div>
            </ul>
          )
          : (
            <ul className="flex flex-col xl:flex-row xl:space-x-3
             space-y-1 xl:space-y-0 text-gray-400 text-body-sm mb-5"
            >
              <FeaturedBidDetails
                icon={mdiMapMarker}
                value={`${rfpItem.location.address_1}, 
             ${rfpItem.location.city} ${rfpItem.location.state} ${rfpItem.location.zip}`}
              />
              {rfpItem.waste_stream
                && <FeaturedBidDetails icon={mdiRecycleVariant} value={rfpItem.waste_stream.name} />}
              <FeaturedBidDetails
                icon={mdiDumpTruck}
                value={`
           ${rfpItem.quantity} x
           ${rfpItem.container.container_type.name}
           ${rfpItem.container.container_sizes.map((cs) => cs.name).join(', ')}
           `}
              />
              <FeaturedBidDetails icon={mdiTimetable} value={rfpItem.pickup_frequency.name} />
            </ul>
          )}
        <div className="-mt-3">
          {isModal && rfpItemBid && <BidStatus status={rfpItemBid.rfp_item_bid_status.name} />}
        </div>
      </div>
    </div>
  );
};

const SubmittedBidDetailsSection = ({ title, children }: { title: string, children: ReactNode }) => (
  <div className="mb-3 text-sm">
    <p className="text-text-color-high-em dark:text-text-color-high-em-dark font-semibold">{title}</p>
    {children}
  </div>
);

const SubmittedBidDetails = ({ rfpItemId }: SubmittedBidDetailsProps) => {
  const { isMobile } = useThemeContext();
  const [isDetailsOpen, setIsDetailsOpen] = useState(!isMobile);
  const { data: rfpItemBid } = useSWR(`/bidding/bid/${rfpItemId}/me`);

  const renderRfpItemDetails = rfpItemBid && isDetailsOpen
    && <RfpItemDetails rfpItem={rfpItemBid.rfp_item} isModal rfpItemBid={rfpItemBid} />;

  const renderCharges = rfpItemBid && (
    <div className="bg-background-low-em dark:bg-background-mid-em-dark rounded-md p-4 pb-1 mb-3">
      <SubmittedBidDetailsSection title="Charges">
        <ul className="ml-3 mt-2">
          {Object.entries(rfpItemBid.charges).map(([key, value], i) => (
            <li className="flex flex-row justify-between space-y-1" key={i}>
              <div className="flex flex-row text-body-md dark:text-text-color-low-em text-text-color-mid-em">
                {formatRfpItemBidChargeKey(key)}
                {': '}
              </div>
              <span className="label-md text-text-color-high-em dark:text-text-color-high-em-dark">
                {value}
              </span>
            </li>
          ))}
        </ul>
      </SubmittedBidDetailsSection>
    </div>

  );

  const renderRfpBidDetails = rfpItemBid && (
    <div>
      {isMobile ? (
        <Fragment>
          <SubmittedBidChargeBreakdown charges={rfpItemBid?.charges || []} />
          <SubmittedBidRemarks
            submittedBy={rfpItemBid?.created_by_user?.full_name || ''}
            submittedByEmail={rfpItemBid?.created_by_user?.email || ''}
            note={rfpItemBid?.note || ''}
          />
        </Fragment>
      ) : (
        <Fragment>
          {renderCharges}
          <SubmittedBidDetailsSection title="Submitted By">
            <p className="text-body-md text-text-color-mid-em dark:text-text-color-high-em-dark">
              {`${rfpItemBid.created_by_user.full_name} (${rfpItemBid.created_by_user.email})`}
            </p>
          </SubmittedBidDetailsSection>
          <SubmittedBidDetailsSection title="Note">
            <p className="text-body-md text-text-color-mid-em dark:text-text-color-high-em-dark">
              {rfpItemBid.note}
            </p>
          </SubmittedBidDetailsSection>
        </Fragment>
      )}
    </div>
  );

  const renderMain = (
    <div className="w-[80vw] md:w-auto">
      <button
        type="button"
        className="font-bold mb-5 cursor-pointer flex items-center"
        onClick={() => setIsDetailsOpen(!isDetailsOpen)}
      >
        Bid Details for
        {' '}
        {rfpItemBid && rfpItemBid.rfp_item.rfp.client.name}
        <span>
          {isDetailsOpen ? (
            <Icon
              size={1}
              path={mdiMenuUp}
            />
          ) : (
            <Icon
              size={1}
              path={mdiMenuDown}
            />
          ) }
        </span>
      </button>
      {renderRfpItemDetails}
      {renderRfpBidDetails}
    </div>
  );

  return rfpItemBid ? renderMain : <div className="flex justify-center"><PulseLoader /></div>;
};

export default SubmittedBidDetails;
